import React, { useContext, useEffect } from "react";
import { AuthContext } from "./pages/_app";
import Router from "next/router";
import { Spin } from "antd";

const PrivateRoute = (AuthComponent: any) => {
  const PrivateComponent = ({ children }) => {
    const { authenticated = false, loading = true, user } = useContext(
      AuthContext
    );

    useEffect(() => {
      const { pathname } = Router;
      if (loading === false) {
        if (authenticated && pathname === "/") {
          Router.push("/carpets");
        }
        if (!authenticated && !loading) {
          Router.push("/login");
        }
      }
    }, [loading, authenticated, user]);

    if (loading) {
      return (
        <div className="loader">
          <Spin size="large" className="loader" />
        </div>
      );
    }
    return <>{authenticated && <>{children}</>}</>;
  };

  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <AuthComponent {...this.props} />
        </PrivateComponent>
      );
    }
  };
};

export default PrivateRoute;
